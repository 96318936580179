import React, { useMemo, useRef } from "react";
import { SlamString } from "./SlamString";
import { getHideSymbol } from "../../utils";
import styles from "./MenuSideBar.module.scss";
var MenuSideBar = function (_a) {
    var menuList = _a.menuList, menuTitle = _a.menuTitle, changePath = _a.changePath, pathLength = _a.pathLength;
    var isBottom = useMemo(function () { return pathLength === 2; }, [pathLength]);
    var slamFuncList = useRef([]);
    var saveCloseFunc = function (closeFunc) {
        return slamFuncList.current.push(closeFunc);
    };
    var closeAllSlams = function () {
        var _a;
        ((_a = slamFuncList === null || slamFuncList === void 0 ? void 0 : slamFuncList.current) === null || _a === void 0 ? void 0 : _a.length) &&
            slamFuncList.current.forEach(function (func) { return func(); });
    };
    return (<div className={styles.menu_container}>
      {Boolean(pathLength) && (<button type="button" className={styles.menu_back_btn} onClick={function () { return changePath("remove"); }}>
          <span />
        </button>)}

      <p className={styles.menu_title}>{menuTitle}</p>

      <ul className={styles.menu_list_container}>
        {menuList.map(function (_a, indx) {
            var id = _a.id, title = _a.title, url = _a.url, page = _a.page, target = _a.target, children = _a.children, isPrimaryButton = _a.isPrimaryButton, isSecondaryButton = _a.isSecondaryButton;
            var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
            if (isPrimaryButton || isSecondaryButton) {
                return null;
            }
            if (title === getHideSymbol() && children.length) {
                return children.map(function (_a) {
                    var id = _a.id, title = _a.title, target = _a.target, url = _a.url, page = _a.page;
                    var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
                    return (<li key={id} className={styles.menu_list_item}>
                    <a href={href} target={target !== null && target !== void 0 ? target : "_self"} className={styles.string_btn}>
                      {title}
                    </a>
                  </li>);
                });
            }
            if (children.length && !isBottom) {
                return (<li key={id} className={styles.menu_list_item}>
                  <button type="button" onClick={function () { return changePath("add", indx); }} className={styles.string_btn}>
                    <span>{title}</span>
                    <span className={styles.string_icon}/>
                  </button>
                </li>);
            }
            if (isBottom && children.length) {
                return (<li key={id} className={styles.menu_list_item}>
                  <SlamString title={title} children={children} saveCloseFunc={saveCloseFunc} closeAllSlams={closeAllSlams}/>
                </li>);
            }
            if (href && !children.length) {
                return (<li key={id} className={styles.menu_list_item}>
                  <a href={href} target={target !== null && target !== void 0 ? target : "_self"} className={styles.string_btn}>
                    {title}
                  </a>
                </li>);
            }
            return null;
        })}
      </ul>
    </div>);
};
export { MenuSideBar };
