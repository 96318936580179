var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useMemo } from "react";
import { SideBar, MenuSideBar, ContactsSideBar, ServiceSideBar, } from "../mob-components";
import { AuthBtn } from "../components";
import styles from "./MobileMenu.module.scss";
var MobileMenu = function (_a) {
    var logo = _a.logo, globalList = _a.globalList, prodList = _a.prodList, serviceList = _a.serviceList;
    var _b = useState(false), isOpenMenu = _b[0], setOpenMenu = _b[1];
    var _c = useState(false), isOpenContacts = _c[0], setOpenContacts = _c[1];
    var _d = useState(false), isOpenServices = _d[0], setOpenServices = _d[1];
    var _e = useState([]), menuPath = _e[0], setMenuPath = _e[1];
    var primaryBtn = useMemo(function () { return prodList.filter(function (_a) {
        var isPrimaryButton = _a.isPrimaryButton;
        return isPrimaryButton;
    })[0]; }, [prodList]);
    var secondaryBtn = useMemo(function () { return prodList.filter(function (_a) {
        var isSecondaryButton = _a.isSecondaryButton;
        return isSecondaryButton;
    })[0]; }, [prodList]);
    var menuList = useMemo(function () {
        switch (menuPath.length) {
            case 1:
                return globalList[menuPath[0]].children;
            case 2:
                return globalList[menuPath[0]].children[menuPath[1]].children;
            default:
                return globalList;
        }
    }, [menuPath]);
    var menuTitle = useMemo(function () {
        switch (menuPath.length) {
            case 1:
                return globalList[menuPath[0]].title;
            case 2:
                return globalList[menuPath[0]].children[menuPath[1]].title;
            default:
                return "Меню";
        }
    }, [menuPath]);
    var changePath = function (act, index) {
        if (index === void 0) { index = 0; }
        act === "add" && setMenuPath(__spreadArray(__spreadArray([], menuPath, true), [index], false));
        act === "remove" && setMenuPath(menuPath.slice(0, menuPath.length - 1));
    };
    var toogleSideBar = function (sideBar) { return function () {
        sideBar === "menu" && setOpenMenu(function (state) { return !state; });
        sideBar === "contacts" && setOpenContacts(function (state) { return !state; });
        sideBar === "services" && setOpenServices(function (state) { return !state; });
    }; };
    var closeMenu = function () {
        setOpenMenu(false);
        setMenuPath([]);
    };
    return (<div className={styles.m_menu_container}>
      <div className={styles.m_menu_main_contant}>
        <div className={styles.m_menu_btn_block}>
          <button type="button" className={styles.m_menu_main_btn} onClick={toogleSideBar("menu")}>
            <span />
          </button>
          <button type="button" className={styles.m_menu_contact_btn} onClick={toogleSideBar("contacts")}>
            <span />
          </button>
        </div>
        <img className={styles.m_menu_logo} src={logo} alt="Логотип" width={68} height={68}/>
        <div className={styles.m_menu_btn_block}>
          <button type="button" className={styles.m_menu_service_btn} onClick={toogleSideBar("services")}>
            <span />
          </button>
          <AuthBtn />
        </div>
      </div>

      {(primaryBtn || secondaryBtn) && (<div className={styles.m_menu_btn_block}>
          {primaryBtn && (<a className={styles.m_menu_btn_primary} href={primaryBtn.url}>
              {primaryBtn.title}
            </a>)}
          {secondaryBtn && (<a className={styles.m_menu_btn_secondary} href={secondaryBtn.url}>
              {secondaryBtn.title}
            </a>)}
        </div>)}

      <SideBar isOpen={isOpenMenu} closeFunc={closeMenu}>
        <MenuSideBar menuList={menuList} menuTitle={menuTitle} changePath={changePath} pathLength={menuPath.length}/>
      </SideBar>
      <SideBar isOpen={isOpenContacts} closeFunc={toogleSideBar("contacts")}>
        <ContactsSideBar />
      </SideBar>
      <SideBar position="right" isOpen={isOpenServices} closeFunc={toogleSideBar("services")}>
        <ServiceSideBar serviceList={serviceList}/>
      </SideBar>
    </div>);
};
export { MobileMenu };
