// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6eyG4trpF48RxkGNJyGuKg\\=\\={padding:0 20px}._6B-RybhjUFNu7HvJxsGHIQ\\=\\={color:#202023;font-size:20px;font-weight:700;letter-spacing:-.28px;line-height:28px;padding:16px 0 0}", "",{"version":3,"sources":["webpack://./resources/ts/modules/headers/mob-components/contacts-side-bar/ContactsSideBar.module.scss","webpack://./resources/ts/modules/headers/HeaderVars.scss"],"names":[],"mappings":"AAGE,6BACE,cAFJ,CAKE,6BAKE,aCNgB,CDEhB,cAAA,CACA,eAAA,CAEA,qBAAA,CADA,gBAAA,CAGA,gBAHJ","sourcesContent":["@import \"../../HeaderVars.scss\";\n\n.contacts_s {\n  &_container {\n    padding: 0 20px;\n  }\n\n  &_title {\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 28px;\n    letter-spacing: -0.28px;\n    color: $labelPrimaryColor;\n    padding: 16px 0 0 0;\n  }\n}\n","$headerMainColor: #161618;\n$headerLinkColor: rgba(255, 255, 255, 0.72);\n$headerLinkActiveColor: #ffffff;\n$headerUnderlineColor: #4a494f;\n$headerLayoutColor: rgba(0, 0, 0, 0.32);\n\n$labelPrimaryColor: #202023;\n$labelSecondaryColor: #797780;\n$linkUnderlineColor: #bababb;\n\n$prodLinkUnderlineColor: #f9af1e;\n$prodLinkColor: #212125;\n$prodLinkHover: #e88b00;\n$prodDescColor: #95929c;\n\n$prodPrimaryBtnColor: #ffc759;\n$prodSecondaryBtnColor: rgba(86, 85, 92, 0.24);\n$moreLinkColor: rgba(10, 10, 10, 0.72);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts_s_container": "_6eyG4trpF48RxkGNJyGuKg==",
	"contacts_s_title": "_6B-RybhjUFNu7HvJxsGHIQ=="
};
export default ___CSS_LOADER_EXPORT___;
