import { Header } from './modules/headers';
import React from 'react';
import { createRoot } from 'react-dom/client';
// @ts-ignore
import { makeStore } from "reduxApp/store";
import { Provider } from 'react-redux';
var store = makeStore();
var reactHeader = document.getElementById('react_header');
if (reactHeader) {
    var root = createRoot(reactHeader);
    root.render(<Provider store={store}>
      <Header />
    </Provider>);
}
