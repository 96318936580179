import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
var useCreatePortalInBody = function () {
    var wrapperRef = useRef(null);
    if (wrapperRef.current === null && typeof document !== "undefined") {
        var div = document.createElement("div");
        div.setAttribute("data-body-portal", "");
        wrapperRef.current = div;
    }
    useEffect(function () {
        var wrapper = wrapperRef.current;
        if (!wrapper || typeof document === "undefined") {
            return;
        }
        document.body.appendChild(wrapper);
        return function () {
            document.body.removeChild(wrapper);
        };
    }, []);
    return function (children) {
        return wrapperRef.current && createPortal(children, wrapperRef.current);
    };
};
export { useCreatePortalInBody };
