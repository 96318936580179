import React, { useEffect, useMemo } from "react";
import { useCreatePortalInBody, useScrollBlock } from "hooks";
import { SubLink } from "..";
import { getColCount, separateListIntoColumns } from "modules/headers/utils";
import styles from "./SubWindow.module.scss";
var HIDE_SYM = ".";
var SubWindow = function (_a) {
    var subContent = _a.subContent, closeWindow = _a.closeWindow;
    var createPortalInBody = useCreatePortalInBody();
    var _b = useScrollBlock(), blockScroll = _b[0], allowScroll = _b[1];
    var colCount = useMemo(function () { return getColCount(subContent === null || subContent === void 0 ? void 0 : subContent.dropColCount); }, [subContent]);
    var linkListByColumns = useMemo(function () { return separateListIntoColumns(subContent === null || subContent === void 0 ? void 0 : subContent.linkList, colCount); }, [subContent]);
    useEffect(function () {
        (subContent === null || subContent === void 0 ? void 0 : subContent.linkList) ? blockScroll() : allowScroll();
    }, [subContent]);
    if (!(subContent === null || subContent === void 0 ? void 0 : subContent.linkList))
        return null;
    return createPortalInBody(<div className={styles.sub_window_container}>
      <div className={styles.sub_window_content}>
        <button type="button" className={styles.sub_close} onClick={closeWindow}/>

        {linkListByColumns.map(function (list, indx) { return (<div key={indx} className={styles.sub_col}>
            {list.map(function (_a) {
                var id = _a.id, title = _a.title, url = _a.url, page = _a.page, children = _a.children, target = _a.target, description = _a.description, labelColor = _a.labelColor, labelText = _a.labelText;
                var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
                if (href && !children.length) {
                    return (<SubLink key={id} title={title} href={href} target={target} description={description} labelColor={labelColor} labelText={labelText}/>);
                }
                return (<div key={id} className={styles.sub_link_block}>
                    {title && title !== HIDE_SYM && (<p className={styles.sub_title}>{title}</p>)}
                    {children.map(function (_a) {
                        var id = _a.id, title = _a.title, url = _a.url, page = _a.page, target = _a.target, description = _a.description, labelColor = _a.labelColor, labelText = _a.labelText;
                        var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
                        return (<SubLink key={id} title={title} href={href} target={target} description={description} labelColor={labelColor} labelText={labelText}/>);
                    })}
                  </div>);
            })}
          </div>); })}
      </div>
      <div className={styles.sub_blanked} onClick={closeWindow}/>
    </div>);
};
export { SubWindow };
