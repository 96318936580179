// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Av8HQu87Dq-ZTtCh2zCgPQ\\=\\={padding:20px}@media only screen and (max-width:374.98px){.Av8HQu87Dq-ZTtCh2zCgPQ\\=\\={padding:20px 8px}}", "",{"version":3,"sources":["webpack://./resources/ts/modules/headers/mob-components/service-side-bar/ServiceSideBar.module.scss","webpack://./resources/ts/styles/mixin.scss"],"names":[],"mappings":"AAIE,4BACE,YAHJ,CCiBI,4CDfF,4BAII,gBAFJ,CACF","sourcesContent":["@import \"styles/mixin.scss\";\n@import \"../../HeaderVars.scss\";\n\n.auth_m {\n  &_container {\n    padding: 20px;\n\n    @include adaptive(\"mob-m\") {\n      padding: 20px 8px;\n    }\n  }\n}\n","// Основные точки адаптива;\n@mixin adaptive($point) {\n  @if $point == desk {\n    @media only screen and (max-width: 1439.98px) {\n      @content;\n    }\n  } @else if $point == tab-l {\n    @media only screen and (max-width: 1279.98px) {\n      @content;\n    }\n  } @else if $point == tab {\n    @media only screen and (max-width: 1023.98px) {\n      @content;\n    }\n  } @else if $point == mob-l {\n    @media only screen and (max-width: 767.98px) {\n      @content;\n    }\n  } @else if $point == mob-m {\n    @media only screen and (max-width: 374.98px) {\n      @content;\n    }\n  } @else if $point == mob {\n    @media only screen and (max-width: 320px) {\n      @content;\n    }\n  }\n}\n\n//Центрирование блока;\n@mixin push--auto {\n  margin: {\n    left: auto;\n    right: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth_m_container": "Av8HQu87Dq-ZTtCh2zCgPQ=="
};
export default ___CSS_LOADER_EXPORT___;
