import React, { useState } from "react";
import { ContactsContent } from "../..";
import styles from "./ContactsBtn.module.scss";
var ContactsBtn = function () {
    var _a = useState(false), isOpen = _a[0], setOpen = _a[1];
    var activeBtnClass = isOpen
        ? styles.contacts_b_drop_btn_active
        : styles.contacts_b_drop_btn_inactive;
    var visibleWindowClass = isOpen
        ? styles.contacts_b_window_visible
        : styles.contacts_b_window_hide;
    var toggleOpen = function () { return setOpen(function (state) { return !state; }); };
    return (<div className={styles.contacts_b_container}>
      <button type="button" className={activeBtnClass} onClick={toggleOpen}>
        <span>Контакты</span>
      </button>

      <div className={visibleWindowClass}>
        <ContactsContent />

        <button type="button" className={styles.contacts_b_close_btn} onClick={toggleOpen}/>
      </div>
    </div>);
};
export { ContactsBtn };
