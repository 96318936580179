import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export var contactsApi = createApi({
    reducerPath: "contacts",
    baseQuery: fetchBaseQuery({}),
    tagTypes: ["Contacts"],
    endpoints: function (builder) { return ({
        getContacts: builder.query({
            query: function (publicApiUrl) {
                var apiUrl = !publicApiUrl
                    ? window.location.origin
                    : publicApiUrl;
                return "".concat(apiUrl, "/api/filials");
            },
        }),
    }); },
});
export var useGetContactsQuery = contactsApi.useGetContactsQuery;
