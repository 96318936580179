import React, { useState } from "react";
import { getCurrentUrl } from "utils/urlTools";
import { DropWindow } from "..";
import styles from "./DropLink.module.scss";
var DropLink = function (_a) {
    var title = _a.title, target = _a.target, url = _a.url, page = _a.page, linkList = _a.linkList, hidden = _a.hidden, dropColCount = _a.dropColCount, dropPosition = _a.dropPosition;
    var _b = useState(false), isOpen = _b[0], setOpen = _b[1];
    var currentUrl = getCurrentUrl();
    var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
    var dropContainerStyle = dropPosition === "full_width"
        ? styles.drop_btn_container_wide
        : styles.drop_btn_container_narrow;
    var hiddenContainerStyle = hidden ? styles.drop_btn_container_hide : "";
    var linkStyle = currentUrl === href ? styles.drop_link_active : styles.drop_link;
    var openDrop = function () { return setOpen(true); };
    var closeDrop = function () { return setOpen(false); };
    var toggleDrop = function (evt) {
        if (evt.code === "Space" || evt.code === "Enter") {
            setOpen(function (state) { return !state; });
        }
    };
    if (href && !linkList.length) {
        return (<a href={href} target={target !== null && target !== void 0 ? target : "_self"} className={"".concat(linkStyle, " ").concat(hiddenContainerStyle)}>
        {title}
      </a>);
    }
    return (<button className={"".concat(dropContainerStyle, " ").concat(hiddenContainerStyle)} onMouseEnter={openDrop} onMouseLeave={closeDrop} onKeyDown={toggleDrop} type="button">
      <span className={styles.drop_btn_text}>{title}</span>

      <DropWindow isOpen={isOpen} openDrop={openDrop} closeDrop={closeDrop} linkList={linkList} dropColCount={dropColCount} dropPosition={dropPosition}/>
    </button>);
};
export { DropLink };
