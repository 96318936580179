import { useEffect, useContext } from "react";
// @ts-ignore
import { useGetContactsQuery } from "reduxApp/api/contactsApi";
import { saveContactsToLS } from "../../../utils";
import { BaseHeaderContext } from "../../../";
var ContactsUrlChecker = function (_a) {
    var AgencyBackOfficeID = _a.AgencyBackOfficeID;
    var _b = useContext(BaseHeaderContext).publicApiUrl, publicApiUrl = _b === void 0 ? null : _b;
    var contactData = useGetContactsQuery(publicApiUrl).data;
    useEffect(function () {
        var _a;
        ((_a = contactData === null || contactData === void 0 ? void 0 : contactData.Filials) === null || _a === void 0 ? void 0 : _a.length) &&
            contactData.Filials.forEach(function (_a) {
                var id = _a.id, tel = _a.tel, address = _a.address, city = _a.city, url = _a.url;
                if (id === AgencyBackOfficeID) {
                    saveContactsToLS({ tel: tel, address: address, city: city, addressUrl: url });
                    window.dispatchEvent(new Event("contacts:changed"));
                }
            });
    }, [AgencyBackOfficeID, contactData]);
    return null;
};
export { ContactsUrlChecker };
