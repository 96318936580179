import React from "react";
import { getCurrentUrl } from "utils/urlTools";
import styles from "./GlobalLink.module.scss";
var GlobalLink = function (_a) {
    var title = _a.title, target = _a.target, url = _a.url, page = _a.page, hidden = _a.hidden;
    var currentUrl = getCurrentUrl();
    var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
    var hiddenContainerStyle = hidden ? styles.link_hide : "";
    var linkStyle = currentUrl.startsWith(href) || title === 'Обучение'
        ? styles.link_active
        : styles.link_base;
    if (!title || !href)
        return null;
    return (<a href={href} target={target !== null && target !== void 0 ? target : "_self"} className={"".concat(linkStyle, " ").concat(hiddenContainerStyle)}>
      {title}
    </a>);
};
export { GlobalLink };
