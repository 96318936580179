export function mapMediaTypeFromBackend(media) {
    return {
        AlternativeText: media.attributes.alternativeText,
        Caption: media.attributes.caption,
        Ext: media.attributes.ext,
        Height: media.attributes.height,
        Mime: media.attributes.mime,
        Name: media.attributes.name,
        Size: media.attributes.size,
        Url: media.attributes.url,
        Width: media.attributes.width,
        id: media.id,
    };
}
export function mapMediaTypeFromKafka(media) {
    return {
        AlternativeText: media.alternativeText,
        Caption: media.caption,
        Ext: media.ext,
        Height: media.height,
        Mime: media.mime,
        Name: media.name,
        Size: media.size,
        Url: media.url,
        Width: media.width,
        id: media.id,
    };
}
