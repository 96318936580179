import React, { useRef, useContext } from "react";
import { ContactsBtn, ServicesBtn, GlobalLink, MoreBtn, AuthBtn, } from "../components";
import { useIndexOfVisibleLink } from "../useIndexOfVisibleLink";
import { BaseHeaderContext } from "../";
import styles from "./GlobalMenu.module.scss";
var GlobalMenu = function (_a) {
    var globalList = _a.globalList, serviceList = _a.serviceList;
    var navMenuRef = useRef(null);
    var contactBlockRef = useRef(null);
    var indxVisibleLink = useIndexOfVisibleLink(navMenuRef, contactBlockRef);
    var chatController = useContext(BaseHeaderContext).chatController;
    return (<div className={styles.global_container}>
      <nav className={styles.global_nav} ref={navMenuRef}>
        {globalList.map(function (_a, indx) {
            var id = _a.id, title = _a.title, url = _a.url, page = _a.page, target = _a.target;
            var isHidden = !isNaN(+indxVisibleLink) && indx > +indxVisibleLink;
            var isMoreBtn = !isNaN(+indxVisibleLink) &&
                globalList.length - 1 !== indxVisibleLink &&
                indx === indxVisibleLink;
            var moreList = globalList.slice(+indxVisibleLink + 1);
            return (<div key={id} className={styles.global_nav_item}>
              <GlobalLink key={id} title={title} url={url} page={page} target={target} hidden={isHidden}/>
              {isMoreBtn && <MoreBtn moreList={moreList} mode="global"/>}
            </div>);
        })}
      </nav>

      <div className={styles.global_right} ref={contactBlockRef}>
        <button type="button" className={styles.global_chat_btn} onClick={function () {
            var _a;
            return ((_a = chatController === null || chatController === void 0 ? void 0 : chatController.current) === null || _a === void 0 ? void 0 : _a.toggleChat) &&
                chatController.current.toggleChat();
        }}/>
        <ContactsBtn />
        <ServicesBtn serviceList={serviceList}/>
        <AuthBtn />
      </div>
    </div>);
};
export { GlobalMenu };
