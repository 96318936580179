import { configureStore } from '@reduxjs/toolkit';
import { contactsApi } from './api/contactsApi';
export var makeStore = function () {
    var _a;
    return configureStore({
        reducer: (_a = {}, _a[contactsApi.reducerPath] = contactsApi.reducer, _a),
        // @ts-ignore
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware().concat(contactsApi.middleware);
        },
        devTools: process.env.NODE_ENV === "development",
    });
};
