export var STUB;
(function (STUB) {
    STUB["publishedTitle"] = "Development page";
    STUB["publishedContent"] = "<h1>\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442 \u0438\u043B\u0438 \u0435\u0449\u0435 \u043D\u0435 \u0431\u044B\u043B\u0430 \u043E\u043F\u0443\u0431\u043B\u0438\u043A\u043E\u0432\u0430\u043D\u0430</h1>";
    STUB["previewContent"] = "<p>\u041E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u0434\u043B\u044F \u043F\u0440\u0435\u0434\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u0430. \u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0443\u0431\u0435\u0434\u0438\u0442\u0435\u0441\u044C, \u0447\u0442\u043E id \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u044B \u0441\u043E\u043E\u0442\u0432\u0435\u0441\u0442\u0432\u0443\u0435\u0442 id \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u0443\u0435\u043C\u043E\u0433\u043E \u043A\u043E\u043D\u0442\u0435\u043D\u0442 \u0431\u043B\u043E\u043A\u0430 \u0432 \u0430\u0434\u043C\u0438\u043D \u043F\u0430\u043D\u0435\u043B\u0438, \u0441 \u0443\u0447\u0435\u0442\u043E\u043C \u0432\u0435\u0440\u0441\u0438\u0438</\u0437>";
})(STUB || (STUB = {}));
export var CONTACTS_DEFAULT;
(function (CONTACTS_DEFAULT) {
    CONTACTS_DEFAULT["city"] = "\u041C\u043E\u0441\u043A\u0432\u0430";
    CONTACTS_DEFAULT["tel"] = "+7 (495) 1-346-346";
    CONTACTS_DEFAULT["address"] = "\u041D\u0430\u0441\u0442\u0430\u0441\u044C\u0438\u043D\u0441\u043A\u0438\u0439 \u043F\u0435\u0440\u0435\u0443\u043B\u043E\u043A, \u0434\u043E\u043C 7, \u0441\u0442\u0440.2";
    CONTACTS_DEFAULT["addressUrl"] = "https://www.finam.ru/home/contacts/";
})(CONTACTS_DEFAULT || (CONTACTS_DEFAULT = {}));
export var ERROR_MESSAGES;
(function (ERROR_MESSAGES) {
    ERROR_MESSAGES["contactsStart"] = "\u041F\u0440\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0435 \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u043E\u0432 \u043F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430. \u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u043E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u0438 \u043F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u044C \u043F\u043E\u043F\u044B\u0442\u043A\u0443.";
    ERROR_MESSAGES["contactsEnd"] = "\u0412 \u0441\u043B\u0443\u0447\u0430\u0435 \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E\u0433\u043E \u0432\u043E\u0437\u043D\u0438\u043A\u043D\u043E\u0432\u0435\u043D\u0438\u044F \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u044B, \u043F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u0442\u0435\u0445\u043D\u0438\u0447\u0435\u0441\u043A\u0443\u044E \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443.";
    ERROR_MESSAGES["chatMessage"] = "\u0412 \u0440\u0430\u0431\u043E\u0442\u0435 \u0447\u0430\u0442\u0430 \u043F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430, \u043F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430 \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C \u0430\u043B\u044C\u0442\u0435\u0440\u043D\u0430\u0442\u0438\u0432\u043D\u044B\u0439 \u0441\u043F\u043E\u0441\u043E\u0431 \u0441\u0432\u044F\u0437\u0438 \u0441 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u043E\u0439.";
})(ERROR_MESSAGES || (ERROR_MESSAGES = {}));
