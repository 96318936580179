import React, { useMemo, useRef } from "react";
import { DropLink, MoreBtn, ProdBtnBlock } from "../components";
import { useIndexOfVisibleLink } from "../useIndexOfVisibleLink";
import styles from "./ProductMenu.module.scss";
var ProductMenu = function (_a) {
    var prodList = _a.prodList;
    var navMenuRef = useRef(null);
    var btnBlockRef = useRef(null);
    var indxVisibleLink = useIndexOfVisibleLink(navMenuRef, btnBlockRef);
    var menuList = useMemo(function () {
        return prodList.filter(function (_a) {
            var isPrimaryButton = _a.isPrimaryButton, isSecondaryButton = _a.isSecondaryButton;
            return !isPrimaryButton && !isSecondaryButton;
        });
    }, [prodList]);
    var primaryBtn = useMemo(function () { return prodList.filter(function (_a) {
        var isPrimaryButton = _a.isPrimaryButton;
        return isPrimaryButton;
    })[0]; }, [prodList]);
    var secondaryBtn = useMemo(function () { return prodList.filter(function (_a) {
        var isSecondaryButton = _a.isSecondaryButton;
        return isSecondaryButton;
    })[0]; }, [prodList]);
    return (<div className={styles.prod_container}>
      <nav className={styles.prod_nav} ref={navMenuRef}>
        {menuList.map(function (_a, indx) {
            var id = _a.id, title = _a.title, target = _a.target, url = _a.url, page = _a.page, children = _a.children, dropPosition = _a.dropPosition, dropColCount = _a.dropColCount;
            var isHidden = !isNaN(+indxVisibleLink) && indx > +indxVisibleLink;
            var isMoreBtn = !isNaN(+indxVisibleLink) &&
                menuList.length - 1 !== indxVisibleLink &&
                indx === indxVisibleLink;
            var moreList = menuList.slice(+indxVisibleLink + 1);
            return (<div key={id} className={styles.prod_nav_item}>
                <DropLink title={title} target={target} url={url} page={page} linkList={children} hidden={isHidden} dropPosition={dropPosition} dropColCount={dropColCount}/>
                {isMoreBtn && <MoreBtn moreList={moreList} mode="prod"/>}
              </div>);
        })}
      </nav>

      <ProdBtnBlock primaryBtn={primaryBtn} secondaryBtn={secondaryBtn} btnBlockRef={btnBlockRef}/>
    </div>);
};
export { ProductMenu };
