import React from "react";
import styles from "./ProdBtnBlock.module.scss";
var ProdBtn = function (_a) {
    var title = _a.title, url = _a.url, target = _a.target, page = _a.page, isPrimaryButton = _a.isPrimaryButton;
    var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
    var btnStyle = isPrimaryButton
        ? styles.prod_btn_primary
        : styles.prod_btn_secondary;
    if (!href)
        return null;
    return (<a href={href} className={btnStyle} target={target !== null && target !== void 0 ? target : "_self"}>
      {title}
    </a>);
};
export { ProdBtn };
