import React, { useState } from "react";
import { ServicesContent } from "../..";
import styles from "./ServicesBtn.module.scss";
var ServicesBtn = function (_a) {
    var serviceList = _a.serviceList;
    var _b = useState(false), isOpen = _b[0], setOpen = _b[1];
    var visibleWindowClass = isOpen
        ? styles.serv_window_visible
        : styles.serv_window_hide;
    var toggleService = function () { return setOpen(function (state) { return !state; }); };
    return (<>
      <div className={styles.serv_container}>
        <button type="button" className={styles.serv_btn} onClick={toggleService}/>

        <div className={visibleWindowClass}>
          <ServicesContent serviceList={serviceList}/>
          <button type="button" className={styles.serv_close_btn} onClick={toggleService}/>
        </div>
      </div>
    </>);
};
export { ServicesBtn };
