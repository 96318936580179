import React, { useContext, useMemo } from "react";
import { ContactContext } from "../contacts-modal-window/ContactsModalWindow";
import { saveContactsToLS, filterAddress } from "../../../utils";
import styles from "./ContactsAddrList.module.scss";
var ContactsAddrList = function () {
    var _a = useContext(ContactContext), contactList = _a.contactList, filter = _a.filter, closeFunc = _a.closeFunc;
    var filtredContactList = useMemo(function () {
        if (!filter)
            return (contactList === null || contactList === void 0 ? void 0 : contactList.length) ? contactList : [];
        var filteredByCity = (contactList === null || contactList === void 0 ? void 0 : contactList.length)
            ? filterAddress(contactList, filter, "city")
            : [];
        var filteredByAddr = (contactList === null || contactList === void 0 ? void 0 : contactList.length)
            ? filterAddress(contactList, filter, "address")
            : [];
        return Object.values(filteredByCity
            .concat(filteredByAddr)
            .reduce(function (acc, cur) {
            var _a;
            return Object.assign(acc, (_a = {}, _a[cur.id] = cur, _a));
        }, {}));
    }, [contactList, filter]);
    var saveCurrentContact = function (contact) { return function () {
        saveContactsToLS(contact);
        window.dispatchEvent(new Event("contacts:changed"));
        closeFunc && closeFunc();
    }; };
    if (!(contactList === null || contactList === void 0 ? void 0 : contactList.length))
        return null;
    return (<ul className={styles.addr_list_container}>
      {filtredContactList.map(function (_a) {
            var id = _a.id, city = _a.city, tel = _a.tel, address = _a.address, url = _a.url;
            var baseContact = { city: city, tel: tel, address: address, addressUrl: url };
            return (<li className={styles.addr_list_item} key={id} onClick={saveCurrentContact(baseContact)}>
            <button type="button" className={styles.addr_list_btn_string}>
              <span className={styles.addr_list_city}>{city}</span>
              <span className={styles.addr_list_address}>{address}</span>
            </button>
          </li>);
        })}
    </ul>);
};
export { ContactsAddrList };
