import React from "react";
import { ServicesContent } from "../../components";
import styles from "./ServiceSideBar.module.scss";
var ServiceSideBar = function (_a) {
    var serviceList = _a.serviceList;
    return (<div className={styles.auth_m_container}>
      <ServicesContent serviceList={serviceList}/>
    </div>);
};
export { ServiceSideBar };
