import React, { useState, useEffect } from "react";
import styles from "./MenuSideBar.module.scss";
var SlamString = function (_a) {
    var title = _a.title, children = _a.children, saveCloseFunc = _a.saveCloseFunc, closeAllSlams = _a.closeAllSlams;
    var _b = useState(false), isOpenSlam = _b[0], setOpenSlam = _b[1];
    var slamListStyle = isOpenSlam
        ? styles.slam_list_visible
        : styles.slam_list_hide;
    var slamIconStyle = isOpenSlam
        ? styles.slam_icon_up
        : styles.slam_icon_down;
    var toggleSlam = function () {
        closeAllSlams();
        !isOpenSlam && setOpenSlam(true);
    };
    var closeSlam = function () { return setOpenSlam(false); };
    useEffect(function () {
        saveCloseFunc(closeSlam);
    }, []);
    return (<>
      <button type="button" onClick={toggleSlam} className={styles.string_btn}>
        <span>{title}</span>
        <span className={slamIconStyle}/>
      </button>
      <div className={slamListStyle}>
        {children.map(function (_a) {
            var id = _a.id, title = _a.title, url = _a.url, target = _a.target;
            return (<a href={url} target={target !== null && target !== void 0 ? target : "_self"} key={id} className={styles.slam_link}>
            {title}
          </a>);
        })}
      </div>
    </>);
};
export { SlamString };
