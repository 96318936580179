import React, { useMemo } from "react";
import { SubLink } from "..";
import { getColCount, separateListIntoColumns, getWindowPosition, getHideSymbol, } from "modules/headers/utils";
import styles from "./DropWindow.module.scss";
var DropWindow = function (_a) {
    var isOpen = _a.isOpen, openDrop = _a.openDrop, closeDrop = _a.closeDrop, linkList = _a.linkList, _b = _a.dropColCount, dropColCount = _b === void 0 ? "column_3" : _b, _c = _a.dropPosition, dropPosition = _c === void 0 ? "full_width" : _c;
    var windowVisibleClass = isOpen
        ? styles.drop_window_visible
        : styles.drop_window_hide;
    var windowBehaviorClass = dropPosition === "full_width"
        ? styles.drop_window_wide
        : styles.drop_window_narrow;
    var columnSizeClass = dropPosition === "full_width"
        ? styles.drop_col_wide
        : styles.drop_col_narrow;
    var position = useMemo(function () { return getWindowPosition(dropPosition); }, [dropPosition]);
    var colCount = useMemo(function () { return getColCount(dropColCount); }, [dropColCount]);
    var linkListByColumns = useMemo(function () { return separateListIntoColumns(linkList, colCount); }, [linkList]);
    return (<div className={"".concat(windowVisibleClass, " ").concat(windowBehaviorClass)} style={position} onMouseEnter={openDrop} onMouseLeave={closeDrop}>
      {linkListByColumns.map(function (list, indx) { return (<div key={indx} className={columnSizeClass}>
          {list.map(function (_a) {
                var id = _a.id, title = _a.title, url = _a.url, page = _a.page, children = _a.children, target = _a.target, description = _a.description, labelColor = _a.labelColor, labelText = _a.labelText;
                var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
                if (href && !children.length) {
                    return (<SubLink key={id} title={title} href={href} target={target} description={description} labelColor={labelColor} labelText={labelText}/>);
                }
                return (<div key={id} className={styles.drop_link_block}>
                  {title && title !== getHideSymbol() && (<p className={styles.drop_title}>{title}</p>)}
                  {children.map(function (_a) {
                        var id = _a.id, title = _a.title, url = _a.url, page = _a.page, target = _a.target, description = _a.description, labelColor = _a.labelColor, labelText = _a.labelText;
                        var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
                        return (<SubLink key={id} title={title} href={href} target={target} description={description} labelColor={labelColor} labelText={labelText}/>);
                    })}
                </div>);
            })}
        </div>); })}
    </div>);
};
export { DropWindow };
