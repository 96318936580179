import { mapMediaTypeFromBackend, mapMediaTypeFromKafka } from "./mediaMapper";
import { mapPageTypeFromBackend, mapPageTypeFromKafka } from "./pageMapper";
function mapMenuItemTypeFromBackend(item) {
    var _a, _b, _c;
    return {
        id: item.id,
        title: item.attributes.title,
        url: item.attributes.url,
        target: item.attributes.target,
        children: ((_a = item.attributes.children) === null || _a === void 0 ? void 0 : _a.data)
            ? item.attributes.children.data.map(function (child) {
                return mapMenuItemTypeFromBackend(child);
            })
            : [],
        page: ((_b = item.attributes.page) === null || _b === void 0 ? void 0 : _b.data)
            ? mapPageTypeFromBackend(item.attributes.page.data)
            : null,
        icon: ((_c = item.attributes.icon) === null || _c === void 0 ? void 0 : _c.data)
            ? mapMediaTypeFromBackend(item.attributes.icon.data)
            : null,
        isPrimaryButton: item.attributes.link_type === "button_primary",
        isSecondaryButton: item.attributes.link_type === "button_secondary",
        isDelimiter: item.attributes.link_type === "delimiter",
        description: item.attributes.description,
        labelColor: item.attributes.label_color,
        labelText: item.attributes.label_text,
        dropColCount: item.attributes.sub_column_number,
        dropPosition: item.attributes.sub_position,
        authTokenAdd: item.attributes.auth_token_add === true,
    };
}
export function mapMenuTypeFromBackend(menu) {
    var _a;
    return {
        id: menu.id,
        title: menu.attributes.title,
        slug: menu.attributes.slug,
        items: ((_a = menu.attributes.items) === null || _a === void 0 ? void 0 : _a.data)
            ? menu.attributes.items.data.map(function (item) {
                return mapMenuItemTypeFromBackend(item);
            })
            : [],
    };
}
export function mapMenuItemTypeFromKafka(item) {
    return {
        id: item.id,
        title: item.title,
        url: item.url,
        target: item.target,
        children: item.children
            ? item.children.map(function (child) { return mapMenuItemTypeFromKafka(child); })
            : [],
        page: item.page ? mapPageTypeFromKafka(item.page) : null,
        icon: item.icon ? mapMediaTypeFromKafka(item.icon) : null,
        isPrimaryButton: item.link_type === "button_primary",
        isSecondaryButton: item.link_type === "button_secondary",
        isDelimiter: item.link_type === "delimiter",
        description: item.description,
        labelColor: item.label_color,
        labelText: item.label_text,
        dropColCount: item.sub_column_number,
        dropPosition: item.sub_position,
        authTokenAdd: item.auth_token_add === true,
    };
}
export function mapMenuTypeFromKafka(menu) {
    return {
        id: menu.id,
        title: menu.title,
        slug: menu.slug,
        items: menu.items
            ? menu.items.map(function (item) { return mapMenuItemTypeFromKafka(item); })
            : [],
    };
}
