import React, { useEffect } from "react";
import { useCreatePortalInBody, useScrollBlock } from "hooks";
import styles from "./SideBar.module.scss";
var SideBar = function (_a) {
    var _b;
    var _c = _a.position, position = _c === void 0 ? "left" : _c, children = _a.children, _d = _a.isOpen, isOpen = _d === void 0 ? false : _d, closeFunc = _a.closeFunc;
    var createPortalInBody = useCreatePortalInBody();
    var _e = useScrollBlock(), blockScroll = _e[0], allowScroll = _e[1];
    var sideBarContainerStyle = isOpen
        ? styles.side_container_visible
        : styles.side_container_hide;
    var closeBar = function (evt) {
        var target = evt.target;
        target.dataset.layout && closeFunc();
    };
    useEffect(function () {
        isOpen ? blockScroll() : allowScroll();
    }, [isOpen]);
    return createPortalInBody(<div className={sideBarContainerStyle} onClick={closeBar} data-layout>
      <div className={styles.side_window} style={_b = {}, _b[position] = 0, _b}>
        <button type="button" className={styles.side_close_btn} onClick={closeFunc}>
          <span />
        </button>
        {children}
      </div>
    </div>);
};
export { SideBar };
