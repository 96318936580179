import React, { useMemo, useState, createContext, useContext } from "react";
import { ClipLoader } from "react-spinners";
// @ts-ignore
import { useGetContactsQuery } from "reduxApp/api/contactsApi";
import { useCreatePortalInBody } from "hooks";
import { ERROR_MESSAGES } from "consts/stubs";
import { ContactsAddrList } from "../contacts-addr-list/ContactsAddrList";
import { ContactsFilter } from "../contacts-filter/ContactsFilter";
import { BaseHeaderContext } from "../../../";
import styles from "./ContactsModalWindow.module.scss";
var ContactContext = createContext({});
var ContactsModalWindow = function (_a) {
    var closeFunc = _a.closeFunc;
    var _b = useContext(BaseHeaderContext).publicApiUrl, publicApiUrl = _b === void 0 ? null : _b;
    var _c = useGetContactsQuery(publicApiUrl), contactData = _c.data, isError = _c.isError, isLoading = _c.isLoading;
    var contactList = useMemo(function () { var _a; return (_a = contactData === null || contactData === void 0 ? void 0 : contactData.data.Filials) !== null && _a !== void 0 ? _a : []; }, [contactData]);
    var _d = useState(""), filter = _d[0], setFilter = _d[1];
    var createPortalInBody = useCreatePortalInBody();
    var closeWindow = function (evt) {
        var target = evt.target;
        target.dataset.layout && closeFunc();
    };
    var changeFilter = function (value) { return setFilter(value); };
    return createPortalInBody(<div className={styles.contacts_modal_layout} onClick={closeWindow} data-layout>
      <div className={styles.contacts_modal_window}>
        <button type="button" className={styles.contacts_modal_close_btn} onClick={closeFunc}>
          <span />
        </button>

        <div className={styles.contacts_modal_contetn}>
          <p className={styles.contacts_modal_title}>Офисы и отделения</p>
          <ClipLoader color="#ffc759" size={40} loading={isLoading} className={styles.contacts_modal_spinner}/>

          {!isError ? (<ContactContext.Provider value={{ contactList: contactList, filter: filter, changeFilter: changeFilter, closeFunc: closeFunc }}>
              <ContactsFilter />
              <ContactsAddrList />
            </ContactContext.Provider>) : (<p className={styles.contacts_modal_error_message}>
              <span>{ERROR_MESSAGES.contactsStart}</span>
              <span>{ERROR_MESSAGES.contactsEnd}</span>
            </p>)}
        </div>
      </div>
    </div>);
};
export { ContactsModalWindow, ContactContext };
