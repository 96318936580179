import React, { useContext } from "react";
import { ContactContext } from "../contacts-modal-window/ContactsModalWindow";
import styles from "./ContactsFilter.module.scss";
var ContactsFilter = function () {
    var _a = useContext(ContactContext), contactList = _a.contactList, filter = _a.filter, changeFilter = _a.changeFilter;
    if (!(contactList === null || contactList === void 0 ? void 0 : contactList.length))
        return null;
    return (<div className={styles.filter_container}>
      <span className={styles.filter_icon}/>
      <input className={styles.filter_input} value={filter} onChange={function (evt) { return changeFilter && changeFilter(evt.target.value); }} placeholder="Найти город"/>
    </div>);
};
export { ContactsFilter };
