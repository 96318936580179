export var getCurrentUrl = function () {
    var origin = typeof window !== "undefined" && window.location.origin
        ? window.location.origin
        : "";
    var pathname = typeof window !== "undefined" && window.location.pathname
        ? window.location.pathname
        : "";
    return "".concat(origin).concat(pathname);
};
var isLocalNetwork = function (hostname) {
    if (hostname === void 0) { hostname = window.location.host; }
    return (hostname.startsWith("localhost") ||
        hostname.startsWith("127.0.0.1") ||
        hostname.startsWith("192.168.") ||
        hostname.startsWith("10.0.") ||
        hostname.endsWith(".local"));
};
export var getAbsoluteUrl = function (req, localhostAddress) {
    if (localhostAddress === void 0) { localhostAddress = "localhost:3000"; }
    var host = ((req === null || req === void 0 ? void 0 : req.headers) ? req.headers.host : window.location.host) ||
        localhostAddress;
    var protocol = isLocalNetwork(host) ? "http:" : "https:";
    if (req &&
        req.headers["x-forwarded-host"] &&
        typeof req.headers["x-forwarded-host"] === "string") {
        host = req.headers["x-forwarded-host"];
    }
    if (req &&
        req.headers["x-forwarded-proto"] &&
        typeof req.headers["x-forwarded-proto"] === "string") {
        protocol = "".concat(req.headers["x-forwarded-proto"], ":");
    }
    return {
        protocol: protocol,
        host: host,
        origin: protocol + "//" + host + req.url,
    };
};
export function trimUrl(url) {
    if (url === "/") {
        return url;
    }
    // Удаляем знак "/" с начала строки
    while (url.charAt(0) === "/") {
        url = url.substring(1);
    }
    // Удаляем знак "/" с конца строки
    while (url.charAt(url.length - 1) === "/") {
        url = url.substring(0, url.length - 1);
    }
    return url;
}
export var createPageUrl = function (urlList, basePath) {
    var url = [];
    if (basePath) {
        url.push(basePath);
    }
    if (urlList && Array.isArray(urlList) && urlList.length > 0) {
        url.push.apply(url, urlList);
    }
    else if (urlList && typeof urlList === "string") {
        url.push(urlList);
    }
    return url.length > 0 ? trimUrl(url.join("/")) : "/";
};
export var createRedirectUrl = function (redirectUrl, query) {
    if (!query) {
        return redirectUrl;
    }
    if ("urlList" in query) {
        delete query.urlList;
    }
    //Если в url есть параметры, то добавляем в конец
    var symbol = redirectUrl.includes("?") ? "&" : "?";
    return (redirectUrl +
        (Object.keys(query).length > 0 ? symbol : "") +
        new URLSearchParams(query).toString());
};
