var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CONTACTS_DEFAULT } from "consts/stubs";
export var getColCount = function (dropColCount) {
    if (dropColCount === "column_1") {
        return 1;
    }
    if (dropColCount === "column_2") {
        return 2;
    }
    return 3;
};
export var separateListIntoColumns = function (list, colCount) {
    var shallowList = list ? __spreadArray([], list, true) : [];
    var resultList = [];
    for (var i = colCount; i > 0; i--) {
        resultList.push(shallowList.splice(0, Math.ceil(shallowList.length / i)));
    }
    return resultList;
};
export var getWindowPosition = function (dropPosition) {
    if (dropPosition === "full_width") {
        return { left: "-120px" };
    }
    if (dropPosition === "right") {
        return { right: "-40px" };
    }
    return { left: "-40px" };
};
export var saveContactsToLS = function (contacts) {
    localStorage.setItem("selectedAgency", JSON.stringify(contacts));
};
export var getContactsFromLS = function (ssrPassed) {
    var _a, _b, _c, _d;
    if (ssrPassed === void 0) { ssrPassed = false; }
    if (typeof window !== "undefined" && ssrPassed) {
        var storageContacts = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem("selectedAgency");
        var contactsData = storageContacts ? JSON.parse(storageContacts) : null;
        var tel = (_a = contactsData === null || contactsData === void 0 ? void 0 : contactsData.tel) !== null && _a !== void 0 ? _a : CONTACTS_DEFAULT.tel;
        var city = (_b = contactsData === null || contactsData === void 0 ? void 0 : contactsData.city) !== null && _b !== void 0 ? _b : CONTACTS_DEFAULT.city;
        var address = (_c = contactsData === null || contactsData === void 0 ? void 0 : contactsData.address) !== null && _c !== void 0 ? _c : CONTACTS_DEFAULT.address;
        var addressUrl = (_d = contactsData === null || contactsData === void 0 ? void 0 : contactsData.addressUrl) !== null && _d !== void 0 ? _d : CONTACTS_DEFAULT.addressUrl;
        return { tel: tel, city: city, address: address, addressUrl: addressUrl };
    }
    return CONTACTS_DEFAULT;
};
export var filterAddress = function (addrList, filter, searchKey) {
    var resultList = [];
    addrList.forEach(function (addr) {
        String(addr[searchKey])
            .toLocaleLowerCase()
            .search(filter.toLocaleLowerCase()) !== -1 && resultList.push(addr);
    });
    return resultList;
};
export var correctTel = function (tel) {
    var regex = /(,.*$)|([^+0-9])/g;
    //Removes all symbols except numbers and plus (+), also removes all extra phones after comma (,)
    return tel.replace(regex, "");
};
export var correctContactUrl = function (url) {
    if (!url)
        return CONTACTS_DEFAULT.addressUrl;
    return url.startsWith("/") ? "http://www.finam.ru".concat(url) : url;
};
export var getHideSymbol = function () { return "."; };
