// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2U-KkdrBMD\\+szzHBHM2\\+BA\\=\\={display:flex;position:relative;width:100%}._6lmIpU2q6cLT2Lv4sJfEZg\\=\\={flex-wrap:wrap;max-height:64px}._6lmIpU2q6cLT2Lv4sJfEZg\\=\\=,.zEZVe7DFaMkju35e7fUubw\\=\\={display:flex}", "",{"version":3,"sources":["webpack://./resources/ts/modules/headers/product-menu/ProductMenu.module.scss"],"names":[],"mappings":"AACE,+BACE,YAAA,CAEA,iBAAA,CADA,UACJ,CAGE,6BAGE,cAAA,CADA,eAAJ,CAGI,yDAJA,YAIJ","sourcesContent":[".prod {\n  &_container {\n    display: flex;\n    width: 100%;\n    position: relative;\n  }\n\n  &_nav {\n    display: flex;\n    max-height: 64px;\n    flex-wrap: wrap;\n\n    &_item {\n      display: flex;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prod_container": "_2U-KkdrBMD+szzHBHM2+BA==",
	"prod_nav": "_6lmIpU2q6cLT2Lv4sJfEZg==",
	"prod_nav_item": "zEZVe7DFaMkju35e7fUubw=="
};
export default ___CSS_LOADER_EXPORT___;
