import React from "react";
import { ContactsContent } from "../../components";
import styles from "./ContactsSideBar.module.scss";
var ContactsSideBar = function () {
    return (<div className={styles.contacts_s_container}>
      <p className={styles.contacts_s_title}>Контакты</p>
      <ContactsContent />
    </div>);
};
export { ContactsSideBar };
