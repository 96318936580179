import React from "react";
import styles from "./SubLink.module.scss";
var SubLink = function (_a) {
    var title = _a.title, href = _a.href, target = _a.target, labelText = _a.labelText, labelColor = _a.labelColor, description = _a.description;
    var backgroundColor = labelColor !== null && labelColor !== void 0 ? labelColor : "white";
    var itemClass = description ? styles.sub_item_max : styles.sub_item_min;
    if (!title || !href)
        return null;
    return (<div className={itemClass}>
      <div className={styles.sub_link_block}>
        <a href={href} target={target !== null && target !== void 0 ? target : "_self"} className={styles.sub_link}>
          {title}
        </a>
        {labelText && (<span style={{ backgroundColor: backgroundColor }} className={styles.sub_ex_label}>
            {labelText}
          </span>)}
      </div>
      {description && <p className={styles.sub_description}>{description}</p>}
    </div>);
};
export { SubLink };
