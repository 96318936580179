import React, { useState, useRef, useEffect, } from "react";
import { useSsrHasPassed, useCreatePortalInBody } from "hooks";
import { ERROR_MESSAGES } from "consts/stubs";
import styles from "./Chat.module.scss";
var CONTAINER_ID = "widget-chat";
var Chat = function (_a) {
    var chatController = _a.chatController, chatAppName = _a.chatAppName, chatAppEnv = _a.chatAppEnv;
    var isSSRFinished = useSsrHasPassed();
    var createPortalInBody = useCreatePortalInBody();
    var _b = useState(false), isOpenChat = _b[0], setOpenChat = _b[1];
    var _c = useState(false), isError = _c[0], setError = _c[1];
    var widget = useRef(null);
    var chatContainerStyle = isOpenChat
        ? styles.chat_visible
        : styles.chat_hide;
    var initChat = function () {
        var _a;
        //@ts-ignore
        var createWidgetFunc = (_a = window === null || window === void 0 ? void 0 : window.txchatwidget) === null || _a === void 0 ? void 0 : _a.create;
        if (createWidgetFunc && !widget.current) {
            var config = {
                containerId: CONTAINER_ID,
                widgetUrl: "https://txchat-widget.finam.ru/",
                appEnv: chatAppEnv,
                logConfig: false,
            };
            var widgetInstance = createWidgetFunc(config);
            if (widgetInstance) {
                widget.current = widgetInstance;
                setError(false);
            }
            else {
                setError(true);
            }
        }
        else if (!createWidgetFunc) {
            setError(true);
        }
        setOpenChat(true);
    };
    var openChat = function () {
        if (!widget.current) {
            initChat();
        }
        else {
            setOpenChat(true);
        }
    };
    var closeChat = function () {
        setOpenChat(false);
    };
    var toggleChat = function () {
        isOpenChat ? closeChat() : openChat();
    };
    useEffect(function () {
        chatController.current.openChat = openChat;
        chatController.current.closeChat = closeChat;
        chatController.current.toggleChat = toggleChat;
    });
    if (!chatAppEnv || !chatAppName)
        return null;
    return isSSRFinished
        ? createPortalInBody(<div className={chatContainerStyle} id={CONTAINER_ID}>
          <div className={styles.chat_header}>
            <div className={styles.chat_title}>Чат поддержки клиентов</div>
            <button type="button" className={styles.chat_close_btn} onClick={closeChat}>
              <span />
            </button>
          </div>

          {isError && (<p className={styles.chat_error}>{ERROR_MESSAGES.chatMessage}</p>)}
        </div>)
        : null;
};
export { Chat };
