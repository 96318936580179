import React from "react";
import { ProdBtn } from "./ProdBtn";
import styles from "./ProdBtnBlock.module.scss";
var ProdBtnBlock = function (_a) {
    var primaryBtn = _a.primaryBtn, secondaryBtn = _a.secondaryBtn, btnBlockRef = _a.btnBlockRef;
    if (!primaryBtn && !secondaryBtn)
        return null;
    return (<div ref={btnBlockRef} className={styles.prod_block_btn}>
      {(primaryBtn === null || primaryBtn === void 0 ? void 0 : primaryBtn.title) && (<ProdBtn title={primaryBtn.title} target={primaryBtn === null || primaryBtn === void 0 ? void 0 : primaryBtn.target} url={primaryBtn === null || primaryBtn === void 0 ? void 0 : primaryBtn.url} page={primaryBtn === null || primaryBtn === void 0 ? void 0 : primaryBtn.page} isPrimaryButton={primaryBtn === null || primaryBtn === void 0 ? void 0 : primaryBtn.isPrimaryButton}/>)}
      {(secondaryBtn === null || secondaryBtn === void 0 ? void 0 : secondaryBtn.title) && (<ProdBtn title={secondaryBtn.title} target={secondaryBtn === null || secondaryBtn === void 0 ? void 0 : secondaryBtn.target} url={secondaryBtn === null || secondaryBtn === void 0 ? void 0 : secondaryBtn.url} page={secondaryBtn === null || secondaryBtn === void 0 ? void 0 : secondaryBtn.page} isPrimaryButton={secondaryBtn === null || secondaryBtn === void 0 ? void 0 : secondaryBtn.isPrimaryButton}/>)}
    </div>);
};
export { ProdBtnBlock };
