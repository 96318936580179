import { mapSiteTypeFromBackend, mapSiteTypeFromKafka } from "./siteMapper";
import { mapMediaTypeFromBackend, mapMediaTypeFromKafka } from "./mediaMapper";
export function mapPageTypeFromBackend(page) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    return {
        CanonicalURL: (_b = (_a = page.attributes.seo) === null || _a === void 0 ? void 0 : _a.canonicalURL) !== null && _b !== void 0 ? _b : null,
        MetaDescription: (_d = (_c = page.attributes.seo) === null || _c === void 0 ? void 0 : _c.metaDescription) !== null && _d !== void 0 ? _d : null,
        MetaImage: ((_g = (_f = (_e = page.attributes) === null || _e === void 0 ? void 0 : _e.seo) === null || _f === void 0 ? void 0 : _f.metaImage) === null || _g === void 0 ? void 0 : _g.data)
            ? mapMediaTypeFromBackend(page.attributes.seo.metaImage.data)
            : null,
        MetaKeywords: (_j = (_h = page.attributes.seo) === null || _h === void 0 ? void 0 : _h.keywords) !== null && _j !== void 0 ? _j : null,
        MetaTitle: (_l = (_k = page.attributes.seo) === null || _k === void 0 ? void 0 : _k.metaTitle) !== null && _l !== void 0 ? _l : null,
        id: page.id,
        Title: page.attributes.Title,
        Url: page.attributes.Url === "/"
            ? page.attributes.Url
            : page.attributes.Url + "/",
        RedirectUrl: page.attributes.redirect_url,
        isPublished: page.attributes.publishedAt !== null,
        isDeleted: false,
        noIndex: (_m = page.attributes.no_index) !== null && _m !== void 0 ? _m : false,
        Body: (_q = (_p = (_o = page.attributes.version) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.attributes.body.htmlCode) !== null && _q !== void 0 ? _q : null,
        Site: ((_s = (_r = page.attributes) === null || _r === void 0 ? void 0 : _r.site) === null || _s === void 0 ? void 0 : _s.data)
            ? mapSiteTypeFromBackend(page.attributes.site.data)
            : null,
        MetaSocial: ((_u = (_t = page.attributes) === null || _t === void 0 ? void 0 : _t.seo) === null || _u === void 0 ? void 0 : _u.metaSocial)
            ? page.attributes.seo.metaSocial.map(function (meta) {
                return mapPageMetaSocialTypeFromBackend(meta);
            })
            : [],
        PageJS: page.attributes.page_js,
        Analytics: {
            GoogleAnalyticsId: page.attributes.google_analytics_id,
            YandexMetrikaId: page.attributes.yandex_metrika_id,
        },
        DateUpdated: page.attributes.updatedAt,
    };
}
export function mapPageTypeFromKafka(page) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    return {
        CanonicalURL: (_b = (_a = page.seo) === null || _a === void 0 ? void 0 : _a.canonicalURL) !== null && _b !== void 0 ? _b : null,
        MetaDescription: (_d = (_c = page.seo) === null || _c === void 0 ? void 0 : _c.metaDescription) !== null && _d !== void 0 ? _d : null,
        MetaImage: ((_e = page.seo) === null || _e === void 0 ? void 0 : _e.metaImage)
            ? mapMediaTypeFromKafka(page.seo.metaImage)
            : null,
        MetaKeywords: (_g = (_f = page.seo) === null || _f === void 0 ? void 0 : _f.keywords) !== null && _g !== void 0 ? _g : null,
        MetaTitle: (_j = (_h = page.seo) === null || _h === void 0 ? void 0 : _h.metaTitle) !== null && _j !== void 0 ? _j : null,
        id: page.id,
        Title: page.Title,
        Url: page.Url === "/" ? page.Url : page.Url + "/",
        RedirectUrl: page.redirect_url,
        isPublished: page.publishedAt !== null,
        isDeleted: page.operation_status === "delete",
        noIndex: (_k = page.no_index) !== null && _k !== void 0 ? _k : false,
        Body: (_o = (_m = (_l = page.version) === null || _l === void 0 ? void 0 : _l.body) === null || _m === void 0 ? void 0 : _m.htmlCode) !== null && _o !== void 0 ? _o : "",
        Site: page.site ? mapSiteTypeFromKafka(page.site) : null,
        MetaSocial: ((_p = page.seo) === null || _p === void 0 ? void 0 : _p.metaSocial)
            ? page.seo.metaSocial.map(function (meta) { return mapPageMetaSocialTypeFromKafka(meta); })
            : [],
        PageJS: page.page_js,
        Analytics: {
            GoogleAnalyticsId: page.google_analytics_id,
            YandexMetrikaId: page.yandex_metrika_id,
        },
        DateUpdated: page.updatedAt,
    };
}
function mapPageMetaSocialTypeFromBackend(meta) {
    var _a;
    return {
        Description: meta.description,
        Image: ((_a = meta === null || meta === void 0 ? void 0 : meta.image) === null || _a === void 0 ? void 0 : _a.data) ? mapMediaTypeFromBackend(meta.image.data) : null,
        SocialNetwork: meta.socialNetwork,
        Title: meta.title,
    };
}
function mapPageMetaSocialTypeFromKafka(meta) {
    return {
        Description: meta.description,
        Image: meta.image ? mapMediaTypeFromKafka(meta.image) : null,
        SocialNetwork: meta.socialNetwork,
        Title: meta.title,
    };
}
