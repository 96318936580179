import React, { useState } from "react";
import { SubWindow } from "..";
import styles from "./MoreBtn.module.scss";
var MoreBtn = function (_a) {
    var moreList = _a.moreList, mode = _a.mode;
    var _b = useState(false), isOpenMore = _b[0], setOpenMore = _b[1];
    var _c = useState({
        linkList: null,
        dropColCount: "",
    }), subContent = _c[0], setSubContent = _c[1];
    var moreWindowBehaviorStyle = isOpenMore
        ? styles.more_window_visible
        : styles.more_window_hide;
    var moreBtnStyle = mode === "prod" ? styles.more_btn_prod : styles.more_btn_global;
    var moreIconStyle = mode === "prod" ? styles.more_icon : styles.more_icon_global;
    var moreWindowViewStyle = mode === "prod" ? styles.more_window_prod : styles.more_window_global;
    var visibleSubWindow = function (children, dropColCount) {
        return setSubContent({
            linkList: children,
            dropColCount: dropColCount,
        });
    };
    var hideSubWindow = function () {
        return setSubContent({
            linkList: null,
            dropColCount: null,
        });
    };
    var openDrop = function () { return setOpenMore(true); };
    var closeDrop = function () { return setOpenMore(false); };
    var toggleDrop = function (evt) {
        if (evt.code === "Space" || evt.code === "Enter") {
            hideSubWindow();
            setOpenMore(function (state) { return !state; });
        }
    };
    return (<div className={styles.more_container}>
      <button type="button" className={moreBtnStyle} onMouseEnter={openDrop} onMouseLeave={closeDrop} onKeyDown={toggleDrop}>
        <span className={moreIconStyle}/>
      </button>
      <div className={"".concat(moreWindowBehaviorStyle, " ").concat(moreWindowViewStyle)} onMouseEnter={openDrop} onMouseLeave={closeDrop}>
        {moreList.map(function (_a) {
            var id = _a.id, title = _a.title, url = _a.url, page = _a.page, children = _a.children, dropColCount = _a.dropColCount;
            var href = url !== null && url !== void 0 ? url : page === null || page === void 0 ? void 0 : page.Url;
            if ((href && !children.length) || mode === "global") {
                return (<a key={id} className={styles.more_window_link} href={href}>
                {title}
              </a>);
            }
            return (<div key={id} className={styles.more_window_link} onClick={function () { return visibleSubWindow(children, dropColCount); }} tabIndex={0}>
              {title}
            </div>);
        })}
      </div>

      <SubWindow subContent={subContent} closeWindow={hideSubWindow}/>
    </div>);
};
export { MoreBtn };
