import { useState, useEffect } from "react";
var useIndexOfVisibleLink = function (navRef, sideBlockRef) {
    var _a = useState(false), indexOfVisibleLink = _a[0], setIndexVisibleLink = _a[1];
    var saveVisibleIndex = function (navLine, btnBlock) { return function () {
        var parentLineBlock = navLine.offsetParent;
        var sideBlockWidth = btnBlock ? btnBlock === null || btnBlock === void 0 ? void 0 : btnBlock.clientWidth : 0;
        var menuLinkList = Array.from(navLine.children);
        var maxNavSize = (parentLineBlock === null || parentLineBlock === void 0 ? void 0 : parentLineBlock.clientWidth) - sideBlockWidth - 24;
        var sumLinkWidth = 0;
        var resultIndex = menuLinkList.length;
        var isFinded = false;
        menuLinkList.forEach(function (link, indx) {
            sumLinkWidth += link === null || link === void 0 ? void 0 : link.clientWidth;
            if (sumLinkWidth >= maxNavSize && !isFinded) {
                resultIndex = indx - 1;
                isFinded = true;
            }
        });
        setIndexVisibleLink(resultIndex);
    }; };
    useEffect(function () {
        if (navRef === null || navRef === void 0 ? void 0 : navRef.current) {
            var navMenu_1 = navRef.current;
            var sideBlock_1 = sideBlockRef.current;
            saveVisibleIndex(navMenu_1, sideBlock_1)();
            window.addEventListener("resize", saveVisibleIndex(navMenu_1, sideBlock_1));
            setTimeout(function () { return window.dispatchEvent(new Event("resize")); }, 200);
            return function () {
                return window.removeEventListener("resize", saveVisibleIndex(navMenu_1, sideBlock_1));
            };
        }
    }, [navRef.current, sideBlockRef.current]);
    return indexOfVisibleLink;
};
export { useIndexOfVisibleLink };
