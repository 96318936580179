import { mapMediaTypeFromBackend, mapMediaTypeFromKafka } from "./mediaMapper";
import { mapPageTypeFromBackend, mapPageTypeFromKafka } from "./pageMapper";
import { mapMenuTypeFromBackend, mapMenuTypeFromKafka, } from "mapper/menuMapper";
export function mapSiteTypeFromBackend(site) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var domains = [];
    if ((_b = (_a = site.attributes) === null || _a === void 0 ? void 0 : _a.domains) === null || _b === void 0 ? void 0 : _b.data) {
        domains = site.attributes.domains.data.map(function (domain) {
            return mapDomainTypeFromBackend(domain);
        });
    }
    var favicon = null;
    if ((_d = (_c = site.attributes) === null || _c === void 0 ? void 0 : _c.favicon) === null || _d === void 0 ? void 0 : _d.data) {
        favicon = mapMediaTypeFromBackend(site.attributes.favicon.data);
    }
    return {
        id: site.id,
        Name: site.attributes.name,
        Title: site.attributes.title,
        GlobalJs: (_e = site.attributes.global_js) !== null && _e !== void 0 ? _e : null,
        MscrmWebsiteId: (_f = site.attributes.mscrm_website_id) !== null && _f !== void 0 ? _f : null,
        Domains: domains,
        Favicon: favicon,
        PageNotFound: ((_h = (_g = site.attributes) === null || _g === void 0 ? void 0 : _g.page_not_found) === null || _h === void 0 ? void 0 : _h.data)
            ? mapPageTypeFromBackend(site.attributes.page_not_found.data)
            : null,
        Logo: ((_k = (_j = site.attributes) === null || _j === void 0 ? void 0 : _j.logo) === null || _k === void 0 ? void 0 : _k.data)
            ? mapMediaTypeFromBackend(site.attributes.logo.data)
            : null,
        Analytics: {
            GoogleAnalyticsId: site.attributes.google_analytics_id,
            YandexMetrikaId: site.attributes.yandex_metrika_id,
            GoogleTagManager: site.attributes.gtm_id,
        },
        ChatAppEnv: site.attributes.chat_app_env,
        ChatAppName: site.attributes.chat_app_name,
        PublicApiUrl: site.attributes.public_api_url,
        AuthEnv: site.attributes.auth_env,
        AuthAppName: site.attributes.auth_app_name,
        Footer: ((_m = (_l = site.attributes) === null || _l === void 0 ? void 0 : _l.footer) === null || _m === void 0 ? void 0 : _m.data)
            ? mapMenuTypeFromBackend(site.attributes.footer.data)
            : null,
        FooterText: site.attributes.footer_text,
        CopyrightText: site.attributes.copyright_text,
        CopyrightYear: site.attributes.copyright_year,
    };
}
export function mapSiteTypeFromKafka(site) {
    var _a, _b;
    return {
        id: site.id,
        Name: site.name,
        Title: site.title,
        GlobalJs: (_a = site.global_js) !== null && _a !== void 0 ? _a : null,
        MscrmWebsiteId: (_b = site.mscrm_website_id) !== null && _b !== void 0 ? _b : null,
        Domains: (site === null || site === void 0 ? void 0 : site.domains)
            ? site.domains.map(function (domain) { return mapDomainTypeFromKafka(domain); })
            : [],
        Favicon: (site === null || site === void 0 ? void 0 : site.favicon) ? mapMediaTypeFromKafka(site.favicon) : null,
        PageNotFound: (site === null || site === void 0 ? void 0 : site.page_not_found)
            ? mapPageTypeFromKafka(site.page_not_found)
            : null,
        Logo: (site === null || site === void 0 ? void 0 : site.logo) ? mapMediaTypeFromKafka(site.logo) : null,
        Analytics: {
            GoogleAnalyticsId: site.google_analytics_id,
            YandexMetrikaId: site.yandex_metrika_id,
            GoogleTagManager: site.gtm_id,
        },
        ChatAppName: site.chat_app_name,
        ChatAppEnv: site.chat_app_env,
        PublicApiUrl: site.public_api_url,
        AuthEnv: site.auth_env,
        AuthAppName: site.auth_app_name,
        Footer: (site === null || site === void 0 ? void 0 : site.footer) ? mapMenuTypeFromKafka(site.footer) : null,
        FooterText: site.footer_text,
        CopyrightText: site.copyright_text,
        CopyrightYear: site.copyright_year,
    };
}
function mapDomainTypeFromBackend(domain) {
    return {
        id: domain.id,
        Domain: domain.attributes.domain,
    };
}
function mapDomainTypeFromKafka(domain) {
    return {
        id: domain.id,
        Domain: domain.domain,
    };
}
