import React, { useState, useContext, useEffect } from "react";
import { useScrollBlock, useSsrHasPassed } from "hooks";
import { ContactsModalWindow, ContactsUrlChecker } from "../..";
import { getContactsFromLS, correctTel, correctContactUrl, } from "../../../utils";
import { BaseHeaderContext } from "../../..";
import styles from "./ContactsContent.module.scss";
var ContactsContent = function () {
    var _a;
    var isSSRFinished = useSsrHasPassed();
    var _b = useState(null), agencyBackOfficeID = _b[0], setAgencyBackOfficeID = _b[1];
    var _c = useScrollBlock(), blockScroll = _c[0], allowScroll = _c[1];
    var chatController = useContext(BaseHeaderContext).chatController;
    var _d = useState(false), isOpenContactsWindow = _d[0], setOpenContactsWindow = _d[1];
    var _e = getContactsFromLS(isSSRFinished), tel = _e.tel, city = _e.city, address = _e.address, addressUrl = _e.addressUrl;
    var toggleContactsWindow = function () {
        setOpenContactsWindow(function (state) { return !state; });
    };
    useEffect(function () {
        isOpenContactsWindow ? blockScroll() : allowScroll();
    }, [isOpenContactsWindow]);
    useEffect(function () {
        var url = new URL(window.location.href);
        var agencyId = url.searchParams.get('AgencyBackOfficeID');
        if (agencyId) {
            setAgencyBackOfficeID(+agencyId);
        }
    }, []);
    return (<>
      <div className={styles.contacts_window_row}>
        <p className={styles.contacts_chat_title}>Написать нам:</p>
        <div className={styles.contacts_chat_list}>
          {((_a = chatController === null || chatController === void 0 ? void 0 : chatController.current) === null || _a === void 0 ? void 0 : _a.toggleChat) && (<button type="button" className={styles.contacts_chat_btn} onClick={function () {
                var _a;
                return ((_a = chatController === null || chatController === void 0 ? void 0 : chatController.current) === null || _a === void 0 ? void 0 : _a.toggleChat) &&
                    chatController.current.toggleChat();
            }}>
              Чат на сайте
            </button>)}
          <a href="mailto:ask@finam.ru" target="_blank" className={styles.contacts_soc_mail}>
            ask@finam.ru
          </a>
        </div>
      </div>

      <div className={styles.contacts_window_row}>
        <div>
          <a href={"tel:".concat(correctTel(tel))} className={styles.contacts_phone}>
            {tel}
          </a>
        </div>
        <a href="tel:*1945" className={styles.contacts_phone}>
          *1945
        </a>
        <p className={styles.contacts_desc}>
          Бесплатно по РФ для МТС, Билайн, МегаФон и Tele2
        </p>
      </div>

      <div className={styles.contacts_window_row}>
        <div>
          <button type="button" className={styles.contacts_city} onClick={toggleContactsWindow}>
            {city}
          </button>
        </div>

        <a href={correctContactUrl(addressUrl)} className={styles.contacts_link}>
          {address}
        </a>
        <button type="button" className={styles.contacts_find} onClick={toggleContactsWindow}>
          <span>Найти ближайший офис</span>
        </button>
      </div>

      <div className={styles.contacts_window_bottom}>
        <span className={styles.contacts_grey_text}>
          Представительства компании в{" "}
          <a href="https://www.finam.ru/about/contacts" className={styles.contacts_bottom_link}>
            70+ городах.
          </a>
          <a href="https://www.finam.ru/landings/partnership" className={styles.contacts_bottom_link}>
            Создайте совместный бизнес
          </a>{" "}
          в своем городе.
        </span>
      </div>

      {isOpenContactsWindow && (<ContactsModalWindow closeFunc={toggleContactsWindow}/>)}

      {agencyBackOfficeID && (<ContactsUrlChecker AgencyBackOfficeID={agencyBackOfficeID}/>)}
    </>);
};
export { ContactsContent };
