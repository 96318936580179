import React, { useRef, createContext } from "react";
import { isMobile } from "react-device-detect";
import { useSsrHasPassed } from "hooks/useSsrHasPassed";
import { GlobalMenu } from "../global-menu/GlobalMenu";
import { ProductMenu } from "../product-menu/ProductMenu";
import { MobileMenu } from "../mobile-menu/MobileMenu";
import { Chat } from "../components";
import styles from "./BaseHeader.module.scss";
var BaseHeaderContext = createContext({});
var BaseHeader = function (_a) {
    var globalMenu = _a.globalMenu, productMenu = _a.productMenu, servicesMenu = _a.servicesMenu, logo = _a.logo, chatAppEnv = _a.chatAppEnv, chatAppName = _a.chatAppName, publicApiUrl = _a.publicApiUrl, isAuthenticated = _a.isAuthenticated;
    var isSSRFinished = useSsrHasPassed();
    var chatController = useRef({});
    if (!globalMenu || !servicesMenu || !(logo === null || logo === void 0 ? void 0 : logo.Url))
        return null;
    return (<>
      <header className={styles.header_container}>
          <BaseHeaderContext.Provider value={{ publicApiUrl: publicApiUrl, isAuthenticated: isAuthenticated, chatController: chatController }}>
              {(!isMobile) && (<div className={styles.header_content}>
                    <a href="/" className={styles.header_logo_link}>
                        <img src={logo.Url} alt="Логотип" width={80} height={80}/>
                    </a>
                  <div className={styles.header_menu_block}>
                    {globalMenu.items.length > 0 && servicesMenu.items.length > 0 && (<GlobalMenu globalList={globalMenu.items} serviceList={servicesMenu.items}/>)}
                    {productMenu.length > 0 && (<ProductMenu prodList={productMenu}/>)}
                  </div>
                </div>)}
            {isMobile && (<div className={styles.header_mobile}>
                  {globalMenu.items.length > 0 && servicesMenu.items.length > 0 && productMenu.length > 0 && (<MobileMenu logo={logo.Url} globalList={globalMenu.items} prodList={productMenu} serviceList={servicesMenu.items}/>)}
                </div>)}
          </BaseHeaderContext.Provider>
      </header>

        <Chat chatController={chatController} chatAppEnv={chatAppEnv} chatAppName={chatAppName}/>
    </>);
};
export { BaseHeader, BaseHeaderContext };
